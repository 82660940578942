import React, { FunctionComponent, useState, useRef, useEffect, useMemo } from 'react'
import EasyTyper from 'easy-typer-js'
import { Link } from 'gatsby'
import { Site } from '../graphql-types'

const Styles = require('../styles/pages/index.module.styl')

type Props = {
  data: {
    site: Site
  }
  location: Location
}

type Book = {
  sentence: string
  from: string
}

const ReadInBook: Book[] = [
  {
    sentence: '痛苦是财富，这话是扯淡。痛苦就是痛苦，对痛苦的思考才是财富。',
    from: '看见',
  },
  {
    sentence: '负面的情绪就像黑暗一样，你是驱散不走它们的。你唯一做的，就是带进光来',
    from: '遇见未知的自己',
  },
  {
    sentence: '当你觉得自己不行的时候你就走到斑马线上，这时候你就是个行人了',
    from: '遇见未知的自己',
  },
]

const Me: FunctionComponent<Props> = () => {
  const [output, setOutput] = useState<string>('')
  const typer = useRef<EasyTyper>()

  const currentBook = useMemo(() => {
    const random = Math.ceil(Math.random() * ReadInBook.length)
    return ReadInBook[random] || ReadInBook[0]
  }, [])

  useEffect(() => {
    // 配置对象
    const obj = {
      output: '',
      isEnd: false,
      speed: 150,
      singleBack: false,
      sleep: 1000,
      type: 'normal',
      backSpeed: 40,
      sentencePause: false,
    }
    // 实例化
    typer.current = new EasyTyper(
      obj,
      currentBook.sentence,
      () => {},
      (output: string) => {
        setOutput(output)
      },
    )

    return () => {
      typer.current?.close()
    }
  }, [])

  return (
    <>
      <div className={Styles['content']}>
        <div className={Styles['word']}>
          <div className={Styles['sentence']}>
            {output}
            <span className={Styles['cursor']}>|</span>
          </div>
          <div className={Styles['from']}> --《{currentBook.from}》</div>
        </div>
        <Link className={Styles['link']} to='/posts'>
          Enter
        </Link>
      </div>
    </>
  )
}

export default Me
